<template>
  <table-view
    class="category-container"
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable"
  >
    <template #header>
      <view-search-form
        ref="searchFormRef"
        :queryInfo.sync="queryInfo"
        :tool-list="['grade', 'college', 'major', 'stuStatus', 'classesNoNullFlag','sexType','keyword']"
        :insert-select-all="[ 'college']"
        :filter-keys="['orderByDormitoryFlag']"
        :is-grade-clearable="false"
        @on-search="renderTable(1)" />
      <div class="fl" v-if="rowCheckList.length" style="margin-top: 5px;">
        <span>当前选择：</span>
        <span>男：<b style="vertical-align: -1px;">{{ majorInfo.man }}</b>， </span>
        <span>女：<b style="vertical-align: -1px;">{{ majorInfo.girl }}</b>， </span>
        <span>共<b style="vertical-align: -1px;">{{ rowCheckList.length }}</b>人</span>
      </div>
      <div class="header-button">
        <el-button
          v-permission="['class:category:allot']"
          type="primary"
          size="small"
          :disabled="!rowCheckList.length"
          @click="showBatchBindDialog"
        >批量分配班级
        </el-button>
      </div>
    </template>
    <!-- body -->
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      stripe
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="40" />
      <el-table-column prop="examineNo" label="录取编号" width="100" align="center" />
      <el-table-column prop="grade" label="年级" width="55" align="center" />
      <el-table-column prop="stuName" label="姓名" width="100" />
      <el-table-column label="学籍状态" width="110">
        <template v-slot="{row}">
          {{ $http.getParamName(row.stuStatus, $store.state.systemParam.stuStatus) }}
        </template>
      </el-table-column>
      <el-table-column prop="sexType" label="性别" width="60" align="center">
        <template v-slot="{row}">
          {{ Number(row.sexType) === 1 ? '男' : '女' }}
        </template>
      </el-table-column>
      <el-table-column prop="collegeName" label="院系" width="150" />
      <el-table-column prop="majorName" label="专业" width="200" />
      <el-table-column prop="className" label="班级" width="200">
        <template v-slot="{row}">
          <span>{{ row.className||'未绑定' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="job" label="职务" width="100" />
      <el-table-column prop="apartmentName" label="宿舍" width="170">
        <template v-slot="{row}">
            <span v-if="row.dormitoryName && row.dormitoryBedNo"
            >{{ row.dormitoryName }}{{ row.roomName }}室{{
                row.dormitoryBedNo
              }}号床</span>
          <span v-else>未分配</span>
        </template>
      </el-table-column>
      <el-table-column prop="fixedPhone" label="联系电话" width="120" />
      <el-table-column prop="remarks" label="备注" min-width="180" />
      <el-table-column label="操作" width="100" fixed="right" align="center">
        <template v-slot="{row}">
          <el-button v-permission="['class:category:allot']" v-if="+row.stuStatus===1" type="primary" size="mini" @click="showDialog(row)">分配班级</el-button>
          <span v-if="+row.stuStatus===0" class="font-grey">未注册</span>
        </template>
      </el-table-column>
    </el-table>
    <selectClass
      ref="dialogRef"
      :grade="this.queryInfo.grade"
      :major-id="this.queryInfo.majorId"
      :visible.sync="visibleDialog"
      v-if="visibleDialog"
      @on-close="renderTable" />
  </table-view>
</template>

<script>
import { getStudentList } from '@/api/student'
import selectClass from './dialog/selectClass.vue'
import tableView from '@/vue/mixins/table-view'

export default {
  components: {
    selectClass
  },
  mixins: [tableView],
  data () {
    return {
      queryInfo: {
        campusId: '',
        grade: new Date().getFullYear(),
        collegeId: '',
        majorId: '',
        sexType: null,
        stuStatus: null,
        orderByDormitoryFlag: 1,
        classesNoNullFlag: null
      },
      majorInfo: {
        man: null,
        girl: null
      },
      rowCheckList: []
    }
  },
  methods: {
    renderTable (pageNum) {
      this.getTableData(getStudentList, pageNum)
    },
    // 学生多选
    handleSelectionChange (list) {
      this.rowCheckList = list
      this.majorInfo.man = list.filter(
        (item) => Number(item.sexType) === 1
      ).length
      this.majorInfo.girl = list.filter(
        (item) => Number(item.sexType) === 2
      ).length
    },
    // 打开分配班级弹窗
    showDialog (row) {
      this.visibleDialog = true
      this.$nextTick(() => {
        this.$refs.dialogRef.title = `${row.stuName}(录取编号:${row.examineNo})`
        this.$refs.dialogRef.formData.id = row.id
        this.$refs.dialogRef.majorInfo = {
          majorId: row.majorId,
          majorName: row.majorName,
          grade: row.grade
        }
        this.$refs.dialogRef.setInitData()
      })
    },
    // 批量分配班级  打开dialog
    showBatchBindDialog () {
      // 获取选中行的majorId 判断,不同专业不可同时分配
      const majorIds = this.rowCheckList.map((stu) => stu.majorId)
      const arrSet = new Set(majorIds)
      if (arrSet.size > 1) {
        this.$message.warning('请选择相同专业的学生')
        return false
      }
      // 获取选中的学生id和专业等
      const stuIds = this.rowCheckList.map((stu) => stu.id)
      this.visibleDialog = true
      this.$nextTick(() => {
        this.$refs.dialogRef.formData.stuIds = stuIds
        this.$refs.dialogRef.title = `专业：${this.rowCheckList[0].majorName}【共${this.rowCheckList.length}人】`
        this.$refs.dialogRef.majorInfo = {
          majorId: this.rowCheckList[0].majorId,
          majorName: this.rowCheckList[0].majorName,
          grade: this.rowCheckList[0].grade
        }
      })
    }
  }
}
</script>
