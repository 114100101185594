<template>
  <el-dialog
    class="select-class-dialog"
    :title="`分配班级-${title}`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="720px"
    :before-close="cancel"
  >
    <el-form
      v-loading="loadData.loading"
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="100px"
      class="form-style"
    >
      <el-form-item label="所属专业" prop="majorId">
        <el-input disabled :value="majorInfo.majorName" />
      </el-form-item>
      <el-form-item label="选择班级" prop="classesId">
        <el-select v-model="formData.classesId" filterable clearable>
          <el-option
            v-for="{className, id} in classList"
            :label="className"
            :value="id"
            :key="id" />
        </el-select>
      </el-form-item>
      <el-table :data="classList" style="width: 100%" border stripe>
        <el-table-column type="index" label="ID" width="45" align="center" />
        <el-table-column prop="className" label="班级" />
        <el-table-column prop="manNum" label="男生" width="80" align="center" />
        <el-table-column prop="womanNum" label="女生" width="80" align="center" />
        <el-table-column prop="stuNum" label="班内现有人数" width="140" align="center" />
      </el-table>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag"/>
      <el-button type="primary" @click="handleSave">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import campusSchMajorClasses from '@/vue/mixins/campusSchMajorClasses'

export default {
  mixins: [dialog, campusSchMajorClasses],
  data () {
    return {
      formData: {
        id: null,
        classesId: '', // 班级
        stuIds: []
      },
      majorInfo: {
        grade: null,
        majorId: '',
        majorName: ''
      },
      successFlag: false, // 用于判断是否成功分配
      rules: {
        classesId: [{ required: true, message: '请选择班级', trigger: 'blur' }]
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.majorInfo.majorId) {
        this.getClassList({
          grade: this.majorInfo.grade,
          majorId: this.majorInfo.majorId
        })
      }
    })
  },
  methods: {
    handleSave () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm(`此操作将为${this.title}分配班级, 是否继续?`, '提示').then(async () => {
            this.loadData.loading = true
            // 单个分配
            if (this.formData.id) {
              await this.bindStus([this.formData.id])
            } else {
              // 批量分配
              await this.bindStus(this.formData.stuIds)
            }
            if (this.successFlag) {
              this.$message.success('分配成功!')
              this.closed()
            }
            this.loadData.loading = false
          }).catch()
        } else {
          this.$message('未通过校验,可能是漏填必填项!')
          return false
        }
      })
    },
    // 绑定班级
    async bindStus (idList) {
      const _stuInfos = idList.map(id => {
        return {
          id: id,
          classesId: this.formData.classesId
        }
      })
      try {
        await this.$http.updateStudentInfo({ stuInfoList: _stuInfos })
        this.successFlag = true
      } catch (e) {
        this.$message.error('分配失败，请稍后重试！')
        this.close()
      }
    }
  }
}
</script>
